<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @ok="modalOk"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <b-button class="history-button" variant="secondary" size="sm" @click="state.historyShow = true">
            <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
            {{ $t('button.history') }}
          </b-button>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('contact.title_singular').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('contact.title_singular').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <AvatarBanner v-model="avatarBanner" :readOnly="isReadOnly" @status="avatarBannerStatus"/>
        <b-alert variant="danger" dismissible :show="showAlert" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <b-row>
          <b-col v-if="canView(permissionName, ['firstName'])" cols="12" md="8">
            <b-form-group :label="$t(`contact.field.firstName`)" label-for="firstName">
              <b-input-group>
                <b-form-input id="firstName" type="text"
                  :data-vv-as="$t('contact.field.firstName')"
                  data-vv-name="contact.firstName"
                  data-vv-delay="500"
                  v-model="contact.firstName"
                  v-validate="{ required: true }"
                  :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['firstName'])) || (exists && !canEdit(permissionName, ['firstName']))"
                  :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'contact.firstName')">
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFirstNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('contact.firstName') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['firstName'] != null">
            <b-col v-for="(field, index) in customFieldMap['firstName']" :key="'firstName'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col v-if="canView(permissionName, ['identifier'])" cols="12" md="4" class="pl-md-0">
            <b-form-group :label="$t('field.identifier')" label-for="identifier">
              <b-input-group>
                <b-form-input id="identifier" type="text"
                  :data-vv-as="$t('field.identifier')"
                  data-vv-name="contact.identifier"
                  :maxlength="maxIdentifierLength"
                  v-model="contact.identifier" 
                  :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['identifier'])) || (exists && !canEdit(permissionName, ['identifier']))"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['identifier'] != null">
            <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView(permissionName, ['lastName'])" cols="12">
            <b-form-group  :label="$t('contact.field.lastName')" label-for="lastName">
              <b-input-group>
                <b-form-input id="lastName" type="text"
                  :data-vv-as="$t('contact.field.lastName')"
                  data-vv-name="contact.lastName"
                  data-vv-delay="500"
                  v-model="contact.lastName" 
                  v-validate="{ required: true }"
                  :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['identifier'])) || (exists && !canEdit(permissionName, ['identifier']))"
                  :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'contact.lastName')">
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLastNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('contact.lastName') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['lastName'] != null">
            <b-col v-for="(field, index) in customFieldMap['lastName']" :key="'lastName'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView(permissionName, ['position'])" cols="12">
            <b-form-group :label="$t(`contact.field.position`)" label-for="position">
              <b-input-group>
                <b-form-input id="position" type="text"
                  v-model="contact.position"
                  :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['position'])) || (exists && !canEdit(permissionName, ['position']))">
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['position'] != null">
            <b-col v-for="(field, index) in customFieldMap['position']" :key="'position'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView(permissionName, ['company'])" cols="12">
            <b-form-group :label="$t(`contact.field.company`)" label-for="company">
              <b-input-group>
                <b-form-input id="company" type="text"
                  v-model="contact.company"
                  :readonly="isReadOnly || !canView(permissionName, ['company'])">
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['company'] != null">
            <b-col v-for="(field, index) in customFieldMap['company']" :key="'company'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView('LOCATION') && canView(permissionName, ['LOCATION'])" cols="12">
            <b-form-group class="mb-0">
              <label class="mr-1">{{ $t(`project.field.location`) }}</label>
              <button v-if="!isReadOnly && canEdit(permissionName, ['LOCATION'])" class="btn-action" @click="locSelectorOpen"><font-awesome-icon :icon="['far', 'plus']"/></button>
              <BadgeGroup v-model="location" class="mb-3" :readOnly="isReadOnly || !canEdit(permissionName, ['LOCATION'])">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="locationBadgeRemove(index)" @badgeClick="canView('LOCATION') ? locationBadgeClick(item.uuId) : ''"
                    :text="item.name" 
                    variant="primary" 
                    :pillable="!!item.pillable" :key="index"
                    :readOnly="isReadOnly || !canEdit(permissionName, ['LOCATION'])" />
                  </template>
              </BadgeGroup>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['locations'] != null">
            <b-col v-for="(field, index) in customFieldMap['locations']" :key="'locations'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView(permissionName, ['phones'])" cols="12">
            <b-form-group class="mb-0">
              <label class="mr-1">{{ $t(`contact.field.phones`) }}</label>
              <b-row v-for="(phone, index) in contact.phones" v-bind:key="index">
                <b-col lg>
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend v-if="!((isReadOnly || (!exists && !canAdd(permissionName, ['phones'])) || (exists && !canEdit(permissionName, ['phones']))) && phone.kind ==null)">
                        <multiselect v-model="phone.kind" class="custom-dropdown-options data-kind-dropdown enable-option-icon"
                          :max-height="300"
                          :options="optionPhoneNrs.map(i => i.value)"
                          :custom-label="getPhoneKindOptionLabel"
                          :placeholder="$t('contact.select_phone_type')"
                          :searchable="false" 
                          :allow-empty="false"
                          :showLabels="false"
                          :disabled="isReadOnly || (!exists && !canAdd(permissionName, ['phones'])) || (exists && !canEdit(permissionName, ['phones']))">
                          <template slot="option" slot-scope="props">
                            <font-awesome-icon class="selected-option-icon" v-if="phone.kind == props.option" :icon="['far', 'check']" />
                            <span class="option__title">{{ getPhoneKindOptionLabel(props.option) }}</span>
                          </template>
                        </multiselect>
                      </b-input-group-prepend>
                      <b-form-input type="text"
                      v-model="phone.data"
                      :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['phones'])) || (exists && !canEdit(permissionName, ['phones']))"
                      trim></b-form-input>
                      <template v-if="!(isReadOnly || (!exists && !canAdd(permissionName, ['phones'])) || (exists && !canEdit(permissionName, ['phones'])))">
                        <b-input-group-append>
                          <b-button :id="`PHONE_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('phones', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                          <b-popover :target="`PHONE_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.add') }}
                          </b-popover>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button :id="`PHONE_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('phones', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                          <b-popover :target="`PHONE_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.remove') }}
                          </b-popover>
                        </b-input-group-append>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['phones'] != null">
            <b-col v-for="(field, index) in customFieldMap['phones']" :key="'phones'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView(permissionName, ['emails'])" cols="12">
            <b-form-group class="mb-0">
              <label class="mr-1">{{ $t(`contact.field.emails`) }}</label>
              <b-row v-for="(emails, index) in contact.emails" v-bind:key="index">
                <b-col lg>
                  <b-form-group>
                    <b-input-group>
                      <b-form-input type="text" :id='`email`+index' v-model="contact.emails[index]" :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['emails'])) || (exists && !canEdit(permissionName, ['emails']))" trim></b-form-input>
                      <template v-if="!(isReadOnly || (!exists && !canAdd(permissionName, ['emails'])) || (exists && !canEdit(permissionName, ['emails'])))">
                        <b-input-group-append>
                          <b-button :id="`EMAIL_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('emails', index, null)"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                          <b-popover :target="`EMAIL_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.add') }}
                          </b-popover>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button :id="`EMAIL_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('emails', index, null)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>            
                          <b-popover :target="`EMAIL_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.remove') }}
                          </b-popover>
                        </b-input-group-append>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['emails'] != null">
            <b-col v-for="(field, index) in customFieldMap['emails']" :key="'emails'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView(permissionName, ['websites'])" cols="12">
            <b-form-group class="mb-0">
              <label class="mr-1">{{ $t(`contact.field.websites`) }}</label>
              <b-row v-for="(website, index) in contact.websites" v-bind:key="index">
                <b-col lg>
                  <b-form-group>
                    <b-input-group>
                      <b-form-input type="text" :id='`website`+index' v-model="contact.websites[index]" :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['websites'])) || (exists && !canEdit(permissionName, ['websites']))" trim></b-form-input>
                      <template v-if="!(isReadOnly || (!exists && !canAdd(permissionName, ['websites'])) || (exists && !canEdit(permissionName, ['websites'])))">
                        <b-input-group-append>
                          <b-button :id="`WEBSITE_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('websites', index, null)"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                          <b-popover :target="`WEBSITE_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.add') }}
                          </b-popover>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button :id="`WEBSITE_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('websites', index, null)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                          <b-popover :target="`WEBSITE_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.remove') }}
                          </b-popover>
                        </b-input-group-append>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['websites'] != null">
            <b-col v-for="(field, index) in customFieldMap['websites']" :key="'websites'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="canView(permissionName, ['socials'])" cols="12">
            <b-form-group class="mb-0">
              <label class="mr-1">{{ $t(`contact.field.socials`) }}</label>
              <b-row v-for="(social, index) in contact.socials" v-bind:key="index">
                <b-col lg>
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend v-if="!((isReadOnly || (!exists && !canAdd(permissionName, ['socials'])) || (exists && !canEdit(permissionName, ['socials']))) && social.kind ==null)">
                        <multiselect v-model="social.kind" class="custom-dropdown-options data-kind-dropdown enable-option-icon"
                          :max-height="300"
                          :options="optionSocials.map(i => i.value)"
                          :custom-label="getSocialKindOptionLabel"
                          :placeholder="$t('contact.select_socialnet_type')"
                          :searchable="false" 
                          :allow-empty="false"
                          :showLabels="false"
                          :disabled="isReadOnly || (!exists && !canAdd(permissionName, ['socials'])) || (exists && !canEdit(permissionName, ['socials']))">
                          <template slot="option" slot-scope="props">
                            <font-awesome-icon class="selected-option-icon" v-if="social.kind == props.option" :icon="['far', 'check']" />
                            <span class="option__title">{{ getSocialKindOptionLabel(props.option) }}</span>
                          </template>
                        </multiselect>
                      </b-input-group-prepend>
                      <b-form-input type="text"
                        v-model="social.data"
                        :readonly="isReadOnly || (!exists && !canAdd(permissionName, ['socials'])) || (exists && !canEdit(permissionName, ['socials']))"
                        trim></b-form-input>
                      <template v-if="!(isReadOnly || (!exists && !canAdd(permissionName, ['socials'])) || (exists && !canEdit(permissionName, ['socials'])))">
                        <b-input-group-append>
                          <b-button :id="`SOCIAL_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('socials', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                          <b-popover :target="`SOCIAL_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.add') }}
                          </b-popover>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button :id="`SOCIAL_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('socials', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                          <b-popover :target="`SOCIAL_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                            {{ $t('button.remove') }}
                          </b-popover>
                        </b-input-group-append>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['socials'] != null">
            <b-col v-for="(field, index) in customFieldMap['socials']" :key="'socials'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="contact[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          <b-col v-if="exists && canView('NOTE') && canView(permissionName, ['NOTE'])" cols="12">
            <b-form-group>
              <NoteList :readOnly="isReadOnly || !canEdit(permissionName, ['NOTE'])" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <template v-if="!readOnly && ((exists && canEdit()) || (!exists && canAdd()))">
          <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success"  v-else-if="!isAccessDenied" @click="modalOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- location selector -->
    <GenericSelectorModalForAdmin v-if="state.locSelectorShow"
      :show.sync="state.locSelectorShow" 
      :entityService="locationUtil" 
      entity="LOCATION"
      nonAdmin
      singleSelection
      @ok="locSelectorOk"
    />
    
    <template v-if="exists">
      <GenericHistoryModal :show.sync="state.historyShow" :id="id" entityType="CONTACT" :customFields="customFields" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
  </div>
</template>

<script>
import { persistNotes } from '@/components/Note/script/crud-util';
import { cloneDeep } from 'lodash';
import { strRandom, processRegExp } from '@/helpers';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { phoneNrs, socialTypes } from '@/selectOptions';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { contactService, contactLinkLocationService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { locationUtil } from '@/views/management/script/location';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ContactModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup.vue'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin'),
    Multiselect
  },
  props: {
    id:        { type: String,  default: `CONTACT_NEW_${strRandom(5)}` },
    holderId:  { type: String,  default: null },
    title:     { type: String,  default: null },
    readOnly:  { type: Boolean, default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'CONTACT',
      modelInfo:      null,
      alertMsg:       null,
      alertMsgDetails: [],
      state: {
        editable:     false,
        isSubmitting: false,
        modalShow:    false,
        locSelectorShow:    false,
        historyShow:        false,
        noteShow:            false
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },
      contact: {
        uuId:         null,
        company:      null,
        position:     null,
        firstName:    null,
        lastName:     null,
        identifier:   null,
        
        emails:     [null],
        websites:   [null],
        messengers: [{kind: null, data: null}],
        phones:     [{kind: null, data: null}],
        socials: [{kind: null, data: null}]
      },
      location: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      
      customFields: [],
      customFieldMap: {},

      isAccessDenied: false,
      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    this.originContact = null;
    if(this.exists) {
      this.contactGet(this.id);
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.originLocId = null;
    this.optionPhoneNrs = phoneNrs;
    this.optionSocials = socialTypes;
    this.optionMessengers = socialTypes;
    this.randomKey = null;
    this.originNotes = [];
    this.locationUtil = locationUtil;
  },
  mounted() {
    this.state.modalShow = this.show;
    if (this.show) {
      this.processWhenShowModal(true)
    }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.optionPhoneNrs = null;
    this.optionSocials = null;
    this.optionMessengers = null;
    this.originLocId = null;
    this.randomKey = null;
    this.originContact = null;
    this.originNotes = null;
    this.locationUtil = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `CONTACT_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showAlert() {
      return this.alertMsg != null;
    },
    showFirstNameError() {
      return fieldValidateUtil.hasError(this.errors, 'contact.firstName');
    },
    showLastNameError() {
      return fieldValidateUtil.hasError(this.errors, 'contact.lastName');
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showLocationError() {
      return fieldValidateUtil.hasError(this.errors, 'locSelector.name');
    },
    showSocialNetPotError() {
      return fieldValidateUtil.hasError(this.errors, 'contact.socials');
    },
    exists() {
      return this.id && this.id.startsWith('CONTACT_NEW') === false;
    },
    labelTitle() {
      return this.title? this.title : this.$t('contact.title_new');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.processWhenShowModal(newValue);
      }
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      this.$validator.pause();
      await getCustomFieldInfo(this, 'CONTACT');
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }
      
      this.resetContactProperties();
      this.state.modalShow = newValue;
      this.state.noteShow = false;
      this.originNotes = [];
      this.notes = [];
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
      this.restrictedRequiredField = null;
      if(this.exists) {
        this.contactGet(this.id, this.holderId);
      } else {
        if (newValue) {
          const requiredFields = ['firstName', 'firstName']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          let result = this.canView2(this.permissionName, requiredFields);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields)
          } 
          
          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
        
        } else {
          this.isAccessDenied = false;
        }
        this.resetContactProperties();
        this.$validator.resume();
      }
    },
    getDisplayNameOfProperty(val) {
      const found = this.customFields.find(i => i.name == val);
      if (found != null) {
        return found.displayName;
      }
      return  this.$t(`contact.field.${val}`);
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "CONTACT"}).then(value => {
        self.modelInfo = value.CONTACT.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    fieldItemAdd(key, index, _default) {
      this.contact[key].splice(index+1, 0, _default);
    },
    fieldItemRemove(key, index, _default) {
      this.contact[key].splice(index, 1);
      if (this.contact[key].length == 0) {
        this.contact[key].push(_default);
      }
    },
    async contactGet(id /** , holderId**/) {
      let data = await contactService.get([{ uuId: id}], ['NOTE', 'LOCATION'])
      .then((response) => {
        return response.data[response.data.jobCase] || [];
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
      
      if(data.length > 0) {
        this.digestResponse(data[0]);
      }
      this.$validator.resume();
    },
    digestResponse(data) {
      const c = this.contact;
      for (const key of Object.keys(c)) {
        c[key] = data[key] || null;
      }

      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          c[field.name] = data[field.name];
        }
      }
      
      c.uuId = data.uuId;
      //Setup avatarBanner data
      const ab = this.avatarBanner;
      if (data.avatarRef || data.bannerRef) {
        ab.avatarId = data.avatarRef;
        ab.bannerId = data.bannerRef;
      }

      this.originContact = cloneDeep(c);
      this.originContact.avatarRef = data.avatarRef != null? data.avatarRef : null;
      this.originContact.bannerRef = data.bannerRef != null? data.bannerRef : null;

      if(c.emails == null || c.emails.length < 1) {
        c.emails = [null];
      }
      if(c.websites == null || c.websites.length < 1) {
        c.websites = [null];
      }
      if(c.messengers == null || c.messengers.length < 1) {
        c.messengers = [{ kind: null, data: null }];
      }
      if(c.phones == null || c.phones.length < 1) {
        c.phones = [{kind: null, data: null}];
      }
      if(c.socials == null || c.socials.length < 1) {
        c.socials = [{ kind: null, data: null }];
      }

      //Setup Location data
      if (data.locationList && data.locationList.length > 0) {
        const list = data.locationList.map(i => { return { uuId: i.uuId, name: i.name }})
        const location = list.length > 0? list[0] : null;
        this.originLocId = location? location.uuId : null;
        if(location) {
          this.location = [{ uuId: location.uuId, name: location.name }];
        }
      }

      //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      const container = this.$refs['comments'];
      if (typeof container !== 'undefined') {
        container.scrollTop = container.scrollHeight;
      }

    },
    modalOk() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.contact[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.contactSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    contactSubmit() {
      const data = cloneDeep(this.contact);
      data['avatarRef'] = this.avatarBanner.avatarId;
      data['bannerRef'] = this.avatarBanner.bannerId;

      // don't send null values for emails, websites, messengers, phones, socials
      let emptyIfNull = i => {
        if(i && i.length > 0 && i[0] === null || i[0].trim().length == 0) {
          i.splice(0, i.length);
        }
      };
      emptyIfNull(data['emails']);
      emptyIfNull(data['websites']);
      
      emptyIfNull = i => {
        if(i && i.length > 0 && i[0] !== null && (i[0].kind === null || i[0].data === null || i[0].data === '')) {
          i.splice(0, i.length);
        }
      };
      emptyIfNull(data['messengers']);
      emptyIfNull(data['phones']);
      emptyIfNull(data['socials']);
      
      let mode = 'update';
      if(!this.exists) {
        delete data['uuId'];
        mode = 'create';
      }
      this.contactPost(mode, data);
    },
    async contactPost(method, data) {
      this.state.isSubmitting = true;
      let contactId = null;
      let result = null;

      //Skip updating access policy if there is no change in access policy properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedContactProperties(data);
      }

      if (method == 'create' || hasChanged) {
        removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
        result = await this.updateContact(method, data);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.state.isSubmitting = false;
          return;
        }
        contactId = result.contactId;
      } else {
        contactId = data.uuId;
      }
      const alertDetails = [];

      result = await this.updateLocation(contactId);
      if(result.hasError) {
        alertDetails.push(result.msg);
      }

      //Notes
      //Remove uuId of new notes before saving
      const notes = cloneDeep(this.notes);
      for (let i = 0, len = notes.length; i < len; i++) {
        if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
          delete notes[i].uuId;
        }
      }      
      // const noteResult = 
      await persistNotes(contactId, this.originNotes, notes);
      //todo: Need to show error to user if error occurs during the note crud operation.

      this.state.isSubmitting = false;
      if(alertDetails.length > 0) {
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length, ...alertDetails);
        this.alertMsg = this.$t(`contact.${method}_partial`);
        this.scrollToTop();
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`contact.${method}`) });
      }
    },
    async updateContact(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`contact.${method}`)
      }
      let contactId = await contactService[method]([data], this.holderId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`contact.error.failed_to_${method}_contact`);
        const response = e.response;
        if (response && 422 === response.status) {
          const feedback = response.data[response.data.jobCase][0];
          const clue = feedback.clue.trim().toLowerCase();
          if(['missing_argument','cannot_be_blank', 'invalid_value',
              'string_limit_exceeded', 'number_limit_exceeded'
              ].includes(clue)) {
            result.msg = this.$t('error.attention_required');
            const fieldKey = `contact.${feedback.args[0]}`;
            const args = [this.$t(`contact.field.${feedback.args[0]}`)];
            let clueNotHandled = false;
            switch (clue) {
              case 'missing_argument': //Do nothing. Doesn't need additional argument
              case 'cannot_be_blank':
              case 'invalid_value':
                break;
              case 'string_limit_exceeded':
              case 'number_limit_exceeded':
                args.push(feedback.args[1]);
                break;
              default:
                clueNotHandled = true;
                result.msg = this.$('error.internal_server'); //reset the msg to internal_server error.
            }
            if (!clueNotHandled) {
              this.errors.add({
                field: fieldKey,
                msg: this.$t(`error.${clue}`, args)
              });
            }
          }
        }
      });
      result.contactId = contactId;
      return result;
    },
    async updateLocation(contactId) {
      const result = {
        hasError: false,
        msgs: this.$t('contact.update_location')
      }
      const originLocId = this.originLocId;
      const locId = this.location.length === 1 ? this.location[0].uuId : null;
      const clues = ['Unknown_target', 'Unknown_relation']
      if(originLocId && originLocId !== locId) {
        await contactLinkLocationService.remove(contactId, [originLocId])
        .catch(e => {
          
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            if(!clues.includes(list[0].clue)) {
              result.hasError = true;
              result.msg = this.$t('contact.error.failed_to_update_location');
            }
          } else {
            result.hasError = true
            result.msg = this.$t('contact.error.failed_to_update_location');
          }
        });
      }
      if(result.hasError) {
        return result;
      }

      if(locId && originLocId !== locId) {
        await contactLinkLocationService.create(contactId, [locId])
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            if('Already_have_edge'!== list[0].clue) {
              result.hasError = true;
              result.msg = this.$t('contact.error.failed_to_update_location');
            }
          } else {
            result.hasError = true
            result.msg = this.$t('contact.error.failed_to_update_location');
          }
        });
      }

      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        if(['missing_argument','cannot_be_blank', 'invalid_value',
            'string_limit_exceeded', 'number_limit_exceeded'
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `contact.${feedback.args[0]}`;
          const args = [this.$t(`contact.field.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
            case 'cannot_be_blank':
            case 'invalid_value':
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
              args.push(feedback.args[1]);
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`error.${clue}`, args)
            });
          }
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
      this.alertMsgDetails = [];
    },
    resetContactProperties() {
      const keys = Object.keys(this.contact);
      this.errors.clear();
      // this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        }
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.contact[keys[i]] = customField.def;
            continue;
          }
        }
        this.contact[keys[i]] = null;
      }
      this.contact.emails = [null];
      this.contact.websites = [null];
      this.contact.messengers = [{kind: null, data: null}];
      this.contact.phones = [{kind: null, data: null}];
      this.contact.socials = [{kind: null, data: null}];

      this.originLocId = null;
      this.location = [];
      this.avatarBanner.avatarId = null;
      this.avatarBanner.bannerId = null;
    },
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
      }
    },
    locSelectorOpen() {
      this.state.locSelectorShow = true;
    },
    locSelectorOk({ details }) {
      this.location = [{ uuId: details[0].uuId, name: details[0].name }];
    },
    clearSelectedLocation() {
      this.location = [];
    },
    locationBadgeRemove: function(index) {
      this.location.splice(index,1);
    },
    locationBadgeClick(/**id**/) {
      this.state.locSelectorShow = true;
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedContactProperties(data) {
      //Remove those properties whose value is not changed in provided data against original contact.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalContact = this.originContact;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      const potKey = ['socials', 'phones', 'messengers'];

      const hasArrayChanged = (oValue, cValue) => {
        if (oValue == null && cValue == null) {
          return false;
        }
        if (oValue == null || cValue == null) {
          return true;
        }
        return JSON.stringify(oValue) != JSON.stringify(cValue);
      }

      for (const key of keys) {
        if (originalContact[key] === data[key]) {
          delete data[key];
          continue;
        } else if ('emails' == key || 'websites' == key || potKey.includes(key)) {
          if (!hasArrayChanged(originalContact[key], data[key])) {
            delete data[key];
            continue;
          }
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    socialsErrors(index) {
      if (this.contact.socials[index].kind === null) {
        return this.$t('contact.error.type');
      }
      return null;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    },
    getPhoneKindOptionLabel(value) {
      return this.optionPhoneNrs.find(i => i.value === value)?.text || value;
    },
    getSocialKindOptionLabel(value) {
      return this.optionSocials.find(i => i.value === value)?.text || value;
    },
  }
}
</script>
