var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.modalTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "success" },
                        on: { click: _vm.ok },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.field.filter"),
                "label-for": "filter",
              },
            },
            [
              _c("FilterComponent", {
                attrs: {
                  disabled: _vm.entity.length === 0,
                  id: "filter",
                  root: _vm.entity.length !== 0 ? _vm.entity[0] : null,
                  userId: _vm.userId,
                  schema: _vm.schema,
                  macros: _vm.macros,
                  predicate: _vm.predicate,
                  allowEditing: false,
                },
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "mr-1" }, [
                    _vm._v(_vm._s(_vm.$t("dataview.field.sortby"))),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn-action",
                      attrs: {
                        disabled: _vm.entity.length === 0,
                        id: "project_filter_sort",
                      },
                      on: { click: _vm.sortSelectorToggle },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "plus"] },
                      }),
                      _c("b-popover", {
                        attrs: {
                          target: "project_filter_sort",
                          placement: "top",
                          triggers: "hover",
                          content: _vm.$t("button.add"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("BadgeGroup", {
                    staticClass: "mb-2",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var item = ref.item
                          var index = ref.index
                          return [
                            _c("Badge", {
                              key: index,
                              attrs: {
                                text: item,
                                variant: "white",
                                pillable: !!item.pillable,
                              },
                              on: {
                                badgeRemove: _vm.removeSortField,
                                badgeClick: _vm.editSortField,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.sortfield,
                      callback: function ($$v) {
                        _vm.sortfield = $$v
                      },
                      expression: "sortfield",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "d-block" }, [
                    _vm._v(_vm._s(_vm.$t("dataview.field.direction"))),
                  ]),
                  _c("b-form-select", {
                    staticClass: "w-auto mb-3",
                    class: _vm.sortfield.length !== 0 ? "mt-2" : "",
                    attrs: {
                      size: "md",
                      disabled: _vm.entity.length === 0,
                      options: _vm.sortDirectionOptions,
                    },
                    model: {
                      value: _vm.sortdirection,
                      callback: function ($$v) {
                        _vm.sortdirection = $$v
                      },
                      expression: "sortdirection",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FieldSelectModal", {
        attrs: {
          show: _vm.showSortSelect,
          root: _vm.entity.length !== 0 ? _vm.entity[0] : null,
          userId: _vm.userId,
          schema: _vm.schema,
          macros: _vm.macros,
          field: _vm.sortfield.length !== 0 ? _vm.sortfield[0] : null,
          useAgFunc: false,
        },
        on: {
          "update:show": function ($event) {
            _vm.showSortSelect = $event
          },
          success: _vm.sortSelectOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }